<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-card>
            <v-card-title>
              Orders
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-select
                  :items="warehouses"
                  item-text="name"
                  item-value="id"
                  label="Warehouse"
                  single-line
                  hide-details
                  v-model="warehouse_id"
                  @change="loadInventory()"
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                @change="searchOrders()"
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :mobile-breakpoint="0"
              :headers="datatable.headers"
              :items="datatable.desserts"
              :server-items-length="datatable.total"
              :loading="datatable.isLoading"
              :options.sync="options"
              :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 20, 50, 100]
              }"
              item-key="id"
            >
              <template v-slot:item.available_quantity="{ item }">
                {{ item.quantity - item.reserved_quantity }}
              </template>
              <template v-slot:item.minimum_quantity="{ item }">
                <b-form-input
                  type="number"
                  @change="updateMinimumQuantity(item)"
                  min="0"
                  step="1"
                  v-model="item.minimum_quantity"
                >
                </b-form-input>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<style scoped lang="scss">
@import "../../../../node_modules/vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import ApiService from "../../../core/services/api.service";
import Vue from "vue";
import _ from "lodash";

const ignoreWarnMessage = `The .native modifier for v-on is only valid on components but it was used on <div>.`;
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function(msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

export default {
  name: "Orders",
  data() {
    return {
      warehouse_id: 1,
      warehouses: [{ id: 1, name: "المتجر" }],
      options: {},
      datatable: {
        total: 0,
        search: "",
        isLoading: false,
        headers: [
          {
            text: "Title",
            align: "left",
            sortable: true,
            value: "title",
            width: "30%"
          },
          {
            text: "SKU",
            sortable: true,
            value: "sku",
            width: "13%"
          },
          {
            text: "Price",
            sortable: true,
            value: "price",
            width: "7%"
          },
          { text: "Quantity", value: "quantity", width: "8%" },
          {
            text: "Available Quantity",
            value: "available_quantity",
            width: "12%",
            sortable: false
          },
          {
            text: "Reserved Quantity",
            value: "reserved_quantity",
            width: "12%"
          },
          { text: "Minimum Quantity", value: "minimum_quantity", width: "12%" }
        ],
        desserts: []
      }
    };
  },
  components: {},
  watch: {
    options: {
      handler() {
        this.loadInventory();
      },
      deep: true
    }
  },
  methods: {
    loadInventory() {
      this.datatable.isLoading = true;

      let sortBy = "";
      let sortDesc = false;
      if (this.options.sortBy.length == 0) {
        sortBy = "title";
        sortDesc = true;
      } else {
        sortBy = this.options.sortBy[0];
        sortDesc = this.options.sortDesc[0];
      }

      ApiService.get(`inventory/${this.warehouse_id}`, {
        per_page: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: sortBy,
        sort: sortDesc ? "DESC" : "ASC",
        search: this.search
      })
        .then(({ data }) => {
          this.datatable.total = data.total;
          this.datatable.desserts = data.data;
        })
        .finally(() => {
          this.datatable.isLoading = false;
        });
    },
    updateMinimumQuantity(item) {
      ApiService.put(
        `inventory/product/${item.product_inventory_id}/minimum_quantity`,
        {
          minimum_quantity: parseInt(item.minimum_quantity)
        }
      )
        .then(() => {
          this.makeToast("success", "Minimum Quantity updated successfully");
        })
        .catch(() => {
          this.makeToast("danger", "Error while updating minimum quantity!");
        });
    },
    loadWarehouses() {
      return ApiService.get("warehouses").then(({ data }) => {
        this.warehouses = data;
      });
    },
    searchOrders() {
      this.options.page = 1;
      this.loadInventory();
    },
    getStatusColor(status) {
      switch (status) {
        case "failed":
          return "red";
        case "sent":
          return "green";
        case "pending":
          return "orange";
      }
      return "secondry";
    },
    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: `Inventory`,
        variant: variant,
        solid: true
      });
    }
  },
  created() {
    this.loadInventory = _.debounce(this.loadInventory, 300);
    this.updateMinimumQuantity = _.debounce(this.updateMinimumQuantity, 1000);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inventory", route: "inventory" }
    ]);
    this.loadInventory();
    this.loadWarehouses();
  }
};
</script>
