var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-card',[_c('v-card-title',[_vm._v(" Orders "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.warehouses,"item-text":"name","item-value":"id","label":"Warehouse","single-line":"","hide-details":""},on:{"change":function($event){return _vm.loadInventory()}},model:{value:(_vm.warehouse_id),callback:function ($$v) {_vm.warehouse_id=$$v},expression:"warehouse_id"}})],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"change":function($event){return _vm.searchOrders()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.datatable.headers,"items":_vm.datatable.desserts,"server-items-length":_vm.datatable.total,"loading":_vm.datatable.isLoading,"options":_vm.options,"footer-props":{
              showFirstLastPage: true,
              itemsPerPageOptions: [5, 10, 20, 50, 100]
            },"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.available_quantity",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity - item.reserved_quantity)+" ")]}},{key:"item.minimum_quantity",fn:function(ref){
            var item = ref.item;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","step":"1"},on:{"change":function($event){return _vm.updateMinimumQuantity(item)}},model:{value:(item.minimum_quantity),callback:function ($$v) {_vm.$set(item, "minimum_quantity", $$v)},expression:"item.minimum_quantity"}})]}}])})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }